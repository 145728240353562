import http from '@/axios'
import { IReport } from '@/interfaces/BaseInterface';
import AuthService from "@/classes/AuthService";

// const TOKEN_PARAM_OBJECT = AuthService.getHybrainTokenObject();
const TOKEN_PARAM_STRING = AuthService.getHybrainTokenQueryString();

class ReportService implements IReport {

    fetchNewMembers(data: string) {
        return http.get(`reports/fetch-new-members?${data}&${TOKEN_PARAM_STRING}`);
    }

    exportNewMembers(data: string) {
        return http.get(`reports/export-new-members/${data}&${TOKEN_PARAM_STRING}`);
    }

    fetchActiveMembers(data: string) {
        return http.get(`reports/fetch-active-members?${data}&${TOKEN_PARAM_STRING}`);
    }

    exportActiveMembers(data: string) {
        return http.get(`reports/export-active-members?${data}&${TOKEN_PARAM_STRING}`);
    }

    fetchTotalMembers(data: string) {
        return http.get(`reports/fetch-total-members?${data}&${TOKEN_PARAM_STRING}`);
    }

    exportTotalMembers(data: string) {
        return http.get(`reports/export-total-members/${data}&${TOKEN_PARAM_STRING}`);
    }

    fetchNewBookings(data: string) {
        return http.get(`reports/fetch-new-bookings?${data}&${TOKEN_PARAM_STRING}`);
    }

    exportNewBookings(data: string) {
        return http.get(`reports/export-new-bookings?${data}&${TOKEN_PARAM_STRING}`);
    }

    fetchIndividualBookings(data: string) {
        return http.get(`reports/fetch-individual-bookings?${data}&${TOKEN_PARAM_STRING}`);
    }

    exportIndividualBookings(data: string) {
        return http.get(`reports/export-individual-bookings?${data}&${TOKEN_PARAM_STRING}`);
    }
    fetchCorporateBookings(data: string) {
        return http.get(`reports/fetch-corporate-bookings?${data}&${TOKEN_PARAM_STRING}`);
    }

    exportCorporateBookings(data: string) {
        return http.get(`reports/export-corporate-bookings?${data}&${TOKEN_PARAM_STRING}`);
    }
}
export default new ReportService();